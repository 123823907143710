import React, { useState,useEffect,useRef } from 'react';
import Bouton from './Bouton';
import './Presenteur.css';
import { Link } from 'react-router-dom';

export const Presenteur = function({titre,explication,img,textBouton,lienBouton}){
   
    const [images,setImages] = useState(img);
    const img1 = useRef();
    const img2 = useRef();

    function slide() {
        setTimeout(function(){
            img1.current && img1.current.classList.add('image-trans');
            img2.current && img2.current.classList.add('image-trans');
            const t = [...images]
            t.push(t.shift());
            
            setTimeout(() => {
                setImages(t)
            }, 3000);           
        },2000)
    }

    useEffect(()=>{
        if(img1 && img2) slide()  ;
        return () => {
            img1.current && img1.current.classList.remove('image-trans');
            img2.current && img2.current.classList.remove('image-trans');
        }
    })
   
  

    return(
        <div className='container px-auto composant'> 
            <h1>{titre}</h1>
            <p>{explication}</p>
            <hr/>
            <div className='container-fluid px-0 d-flex overflow-hidden'>
                <img className='img-fluid' ref={img1}src={images[0]} alt=''/>
                <img className='img-fluid' ref={img2}src={images[1]} alt=''/>
                        
            </div>
            <div className='container w-50 mt-5 d-flex justify-content-center'><Link to={lienBouton}><Bouton>{textBouton}</Bouton></Link></div>
        </div>
    )
}