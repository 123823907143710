import React, { useEffect, useState } from "react";
import TitrePage from "../component/TitrePage";
import Information from "../component/Information";
import Loader from "./Loader";
function Activites() {

    const [activites,setActivites] = useState(null)
    const [loading,setLoading] = useState(true)


useEffect(()=>{
    fetch('https://fairarts.vercel.app/activites').then(response=>response.json())
    .then(data=>{
        setActivites(data)
        setLoading(false)
    })
    .catch(e => console.log('Erreur lors de la récupération des activités',e))
},[])

useEffect(()=>{
    if(activites){
        setLoading(false)
    }
    else{
        setLoading(true)
    }
},[activites])

    return (
        loading ? <Loader /> :
        <>
        <TitrePage titre="Activités du Festival" explication="Retrouvez ici toute l'actualité du festival Fair'Arts in school."/>
        {
            activites && activites.map( (activite,index)=>{
            return(
                    <Information item={activite}
                    key={'activite'+index}
                    rang={index}
                    />
                  )

            })
        }
        
    </>
    );



}
export default Activites;