import React from 'react';
import './TitrePage.css';

const TitrePage = ({titre,explication}) =>{
    return(
        <>
            <h1 className="titre-page"> {titre}<span className="point">.</span></h1>
            <hr className="trame"/>
            <p className="topo">{explication}</p>
        </>
    )
}

export default TitrePage;