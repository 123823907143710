import React, { useEffect, useState } from 'react';



export const Timer = function ({ dateEvenementProps }) {


    function calculateTimeRemaining() {
        const now = new Date();

        const difference = new Date(dateEvenement).getTime() - now.getTime();
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    }

    function formatTime(unit) {
        return unit < 10 ? `0${unit}` : unit;
    }



    const [dateEvenement, setDateEvenement] = useState(dateEvenementProps);
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => clearInterval(interval);
    }, [dateEvenement]);

    return (


        <span style={{ color: 'red' }}> {`   ${formatTime(timeRemaining.days)} jours ${formatTime(timeRemaining.hours)}:${formatTime(timeRemaining.minutes)}:${formatTime(timeRemaining.seconds)}`}</span>


    );
}
