import { React, useEffect, useState } from 'react';
import { Lien } from './Lien'
import './admin.css'
import Loader from "../screens/Loader";




export const F_Troupes = function () {
    const [troupes, setTroupes] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        fetch('https://fairarts.vercel.app/troupes').then(response => response.json())
            .then(data => {
                setTroupes(data)
                setLoading(false)
                console.log(data);

            })
            .catch(e => console.log('Erreur lors de la récupération des troupes', e))
    }, [])

    return (
        loading ? <Loader /> :
            <div className="adminZone">
                <nav >
                    <Lien vers={'F_activites'}></Lien>
                    <Lien vers={'F_articles'}></Lien>
                    <Lien vers={'F_actualites'}></Lien>
                    <Lien vers={'F_talents'}></Lien>
                    <Lien vers={'F_troupes'}></Lien>
                    <Lien vers={'F_Données du sites'}></Lien>
                    <Lien vers={'F_Categories'}></Lien>
                    <Lien vers={'F_Membres'}></Lien>
                    <Lien vers={'F_Sliders'}></Lien>

                </nav>

                <div className="affichage">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">etablissement</th>
                                <th scope="col">nom_troupe</th>
                                <th scope="col">categorie</th>
                                <th scope="col">nombre_membres</th>
                                <th scope="col">encadrant</th>
                                <th scope="col">accompagnateur</th>
                                <th scope="col">prestation solo?</th>
                            </tr>
                        </thead>
                        <tbody>

                            {troupes && troupes.map((troupe, index) => {
                                return (
                                    <tr key={index}>
                                        <th scope="row">{troupe.id}</th>
                                        <td>{troupe.etablissement}</td>
                                        <td>{troupe.nom_troupe}</td>
                                        <td>{troupe.categorie}</td>
                                        <td>{troupe.nombre_membres}</td>
                                        <td>{troupe.encadrant}</td>
                                        <td>{troupe.accompagnateur}</td>
                                        {troupe.solo ? <td>OUI</td> : <td>NON</td> }
                                    </tr>

                                )
                            })}
                        </tbody>
                    </table>


                </div>
            </div>
    )
}