import React, { useState } from 'react';
import "./inscription.css";
import Loader from './Loader';
import { useNavigate } from 'react-router-dom';

const Formulaire = () => {
    const navigate =useNavigate()

    const [loading,setLoading] = useState(false)

    const [inputValue, setInputvalue] = useState({ etablissement: "", nom_troupe: "", categorie: "", nombre_membres: "", encadrant: "", accompagnateur: "" });
    const [soloOption, setSoloOption] = useState('oui')
    const handleSolo = (e) => {
        setSoloOption(e.target.value)
    }
    const handleInputChange = (event) => {
        let data = inputValue;
        switch (event.target.id) {
            case "etablissement":
                data.etablissement = event.target.value;
                setInputvalue({ ...data });
                break;


            case "nom_troupe":
                data.nom_troupe = event.target.value;
                setInputvalue({ ...data });
                break;

            case "categorie":
                data.categorie = event.target.value;
                setInputvalue({ ...data });
                break;
            case "nombre_membres":
                data.nombre_membres = event.target.value;
                setInputvalue({ ...data });
                break;

            case "encadrant":
                data.encadrant = event.target.value;
                setInputvalue({ ...data });
                break;
            case "accompagnateur":
                data.accompagnateur = event.target.value;
                setInputvalue({ ...data });
                break;




            default:
                break;

        };


    };
    const handleSubmitForm = (e) => {
        setLoading(true)
        e.preventDefault();
        var solo
        soloOption === 'oui' ? solo = true : solo = false
        fetch('https://fairarts.vercel.app/troupes/store',
            {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...inputValue ,solo:solo})
            })
            .then((res) => res.json())
            .then((res) => {
                setLoading(false)
                console.log(res)
                navigate('/')
            })
            .catch(e => {
                console.log(e)
                fetch('https://fairarts.vercel.app/troupes/store',
            {
                method: 'POST', headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...inputValue ,solo:solo})
            })
            .then((res) => res.json())
            .then((res) => {
                console.log(res)
                setLoading(false)
                navigate('/')

            })
            .catch(e => console.log(e))
            })
    }


    return (
        <>

            {loading ? <Loader/> : <div className='form-container'>
                <form id='formulaire' onSubmit={handleSubmitForm}>
                    <h1>Inscrivez-Vous</h1>
                    <div className='block'>
                        <label htmlFor='etablissement' className='block'> Nom de l'établissement
                        </label>
                        <input required type='text' id='etablissement' className='block' value={inputValue.etablissement} onChange={handleInputChange} />
                    </div>
                    <div className='block'>
                        <label htmlFor='nom_troupe' className='block'> Nom de la troupe
                        </label>
                        <input required type='text' id='nom_troupe' className='block' value={inputValue.nom_troupe} onChange={handleInputChange} />
                    </div>
                    <div className='block'>
                        <label> Catégorie
                        </label>
                        <select required id='categorie' className='block' value={inputValue.categorie} onChange={handleInputChange}>
                            <option value="" disabled> -- Sélectionnez unz catégorie --</option>
                            <optgroup label='Arts de la scène'>

                                <option value="theâtre"> theâtre</option>
                                <option value="déclamation"> déclamation poétique</option>
                                <option value="lecture performative"> lecture performative</option>
                                <option value="chant-chorale"> chant-chorale</option>
                                <option value="chorégraphie"> chorégraphie</option>
                                <option value="humour"> humour</option>
                                <option value="conte"> conte</option>

                            </optgroup>



                            <optgroup label='Arts Littéraires'>
                                <option value="poème"> poème</option>
                                <option value="roman">roman</option>
                                <option value="drame">drame</option>
                            </optgroup>

                            <optgroup label="Arts Musicaux">
                                <option value="production musicale">production musicale</option>
                                <option value="interprétation">interprétation</option>
                                <option value="Piano"> Piano</option>
                                <option value="Tam-Tam"> Tam-Tam</option>
                                <option value="Tambour"> Tambour</option>
                                <option value="Flûte"> Flûte</option>
                                <option value="Maracas"> Maracas</option>
                                <option value="Danse"> Danse</option>
                            </optgroup>

                            <optgroup label='Arts Plastiques'>
                                <option value="dessin"> dessin</option>
                                <option value="peinture">peinture</option>
                                <option value="sculpture">sculpture</option>
                                <option value="perlage">perlage</option>
                                <option value="body painting">body painting</option>
                                <option value="autres artisanats">autres artisanats</option>
                            </optgroup>

                            <optgroup label='Arts Cinématographique'>
                                <option value="grain pédagogique"> grain pédagogique</option>
                                <option value="court métrage">court métrage</option>
                                <option value="documentaire">documentaire</option>
                            </optgroup>

                            <optgroup label='Arts Culinaires'>
                                <option value="cuisine moderne"> cuisine moderne</option>
                                <option value="cuisine patrimoniale">cuisine patrimoniale</option>
                            </optgroup>

                            <optgroup label='Jeux traditionnels et Patrimoine'>
                                <option value="patrimoine"> patrimoine</option>
                                <option value="Bande-dessinée">Bande-dessinée</option>
                                <option value="Songô minban">Songô minban</option>
                                <option value="Songô Si">Songô Si</option>
                                <option value="Pousse-pion">Pousse-pion</option>
                                <option value="Minban">Minban</option>
                                <option value="Ndorshi">Ndorshi</option>
                            </optgroup>
                        </select>

                    </div>
                    <div className='block'>
                        <label htmlFor='nombre_membres' className='block'> Nombre de membres de la troupe
                        </label>
                        <input type='number' required max={10} id='nombre_membres' className='block' value={inputValue.nombre_membres} onChange={handleInputChange} />
                    </div>

                    <div className='block'>
                        <label htmlFor='encadrant' className='block'> Nom de l'encadrant de la troupe
                        </label>
                        <input required type='text' id='encadrant' className='block' value={inputValue.encadreur} onChange={handleInputChange} />
                    </div>
                    <div className='block'>
                        <label htmlFor='accompagnateur' className='block'> Nom de l'enseignant accompagnateur
                        </label>
                        <input required type='text' id='accompagnateur' className='block' value={inputValue.accompagnateur} onChange={handleInputChange} />
                    </div>
                    <div className='block'>
                        <label htmlFor='solo' className='block'>Est-ce une prestation solo ?</label>
                    </div>
                    <div className='block'>
                        <label htmlFor='solo' className='block'>oui</label>
                        <input type='radio' name='solo' id='oui' className='block' value='oui' checked={soloOption === 'oui'} onChange={handleSolo} />

                    </div>
                    <div className='block'>
                        <label htmlFor='solo' className='block'>non</label>
                        <input type='radio' name='solo' id='non' className='block' value='non' checked={soloOption === 'non'} onChange={handleSolo} />

                    </div>


                    <button type='submit'> Envoyer </button>


                </form>
            </div>}
        </>


    )
}

export default Formulaire;