import React from "react";
import { Link } from 'react-router-dom'
import { Lien } from './Lien'
import './admin.css'

export function AdminPanel() {



    return (
        
        <div className="adminZone">
            <nav>
                <Lien vers={'F_activites'}></Lien>
                <Lien vers={'F_articles'}></Lien>
                <Lien vers={'F_actualites'}></Lien>
                <Lien vers={'F_talents'}></Lien>
                <Lien vers={'F_troupes'}></Lien>
                <Lien vers={'F_Données du sites'}></Lien>
                <Lien vers={'F_Categories'}></Lien>
                <Lien vers={'F_Membres'}></Lien>
                <Lien vers={'F_Sliders'}></Lien>

            </nav>
            <div className="affichage">
                Admininistrer le Site Ici ...
            </div>
        </div>

      
   

        


    )
}