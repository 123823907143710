import React from 'react';
import './Inscription.css';
import Bouton from './Bouton';
import { Link } from 'react-router-dom';

export const Inscription = function({theme,affiche,date,className,contenu}){
    return(
        <div className={'container px-auto '+className}>
            <div className='container theme d-flex align-items-center justify-content-center violet'><div className='container bg-dark'><h2 className='text-center my-3 text-blanc'>{theme}</h2></div></div>
            <div className='row'>
            <div className='col-md-6 '>
                <img className='img-fluid ' src={"https://fairartsinschool.org/uploads/"+affiche} alt=''/>
            </div>
            <div className='col-md-6 my-3'>
                <h2 className='text-center'>Appel  à Candidature</h2>
                <p className='text-center'>{`${new Date(date).getFullYear()}/${(new Date(date).getMonth() + 1).toString().padStart(2, '0')}/${new Date(date).getDate().toString().padStart(2, '0')}`+'\n'+contenu} </p>
                <div className='d-flex justify-content-center'><Link to={'/inscription'}><Bouton>Inscrivez-Vous</Bouton></Link></div>
            </div>
            </div>
        </div>
    )
}
