import { React, useEffect, useState } from 'react';
import { Lien } from './Lien'
import './admin.css'
import Loader from "../screens/Loader";




export const F_NonRel = function () {
    const [nonRel, setNonRel] = useState(null)
    const [loading, setLoading] = useState(true)

    const [formData, setFormData] = useState({
        important: '',
        dateEvent:'',
        theme: '',
        date:'',
        contenu:'',
        affiche: null,
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === 'affiche' ? files[0] : value,
        }));
    };
    
    
    const handleSubmit = (e) => {
        e.preventDefault();

        const dataToSend = new FormData()
        dataToSend.append('image', formData.affiche)
        dataToSend.append('important', formData.important)
        dataToSend.append('theme', formData.theme)
        dataToSend.append('contenu', formData.contenu)
        dataToSend.append('date', formData.date)
        dataToSend.append('dateEvent', formData.dateEvent)

        formData.affiche ? fetch("https://fairarts.vercel.app/image", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },


            body: JSON.stringify(dataToSend),
        }
        )
            .then((response) => response.json())
            .then(donnee => {

                console.log('on y est ok ok ',donnee);
                fetch("http://fairarts.vercel.app/nonRelData/", {
                    method: 'PUT', headers: {
                        'Content-Type': 'application/json',
                    },

                    body: JSON.stringify({ ...formData, affiche: formData.affiche.name }),
                })
                    .then((res) => res.json())
                    .then((res) => console.log(res))
                    .catch((err) => console.log(err))

            })
            .catch((err) => console.log(err)) :

            fetch("http://fairarts.vercel.app/nonRelData/", {
                method: 'PUT', headers: {
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify({ important: formData.important,dateEvent:formData.dateEvent, date: formData.date, contenu: formData.contenu, theme:formData.theme }),
            })
                .then((res) => res.json())
                .then((res) => console.log(res))
                .catch((err) => console.log(err))

    };


    useEffect(() => {

        fetch('https://fairarts.vercel.app/nonRelData').then(response => response.json())
            .then(data => {
                setNonRel(data)
                setLoading(false)
            })
            .catch(e => console.log('Erreur lors de la récupération des Non rel Data ...', e))
      

    })





    return (
        loading ? <Loader /> :
            <div className="adminZone">
                <nav >
                    <Lien vers={'F_activites'}></Lien>
                    <Lien vers={'F_articles'}></Lien>
                    <Lien vers={'F_actualites'}></Lien>
                    <Lien vers={'F_talents'}></Lien>
                    <Lien vers={'F_troupes'}></Lien>
                    <Lien vers={'F_Données du sites'}></Lien>
                    <Lien vers={'F_Categories'}></Lien>
                    <Lien vers={'F_Membres'}></Lien>
                    <Lien vers={'F_Sliders'}></Lien>

                </nav>

                <div className="affichage">

                    {nonRel[0] && nonRel.map((data, index) => {
                        return (
                            <div key={index} className='afficheur' onClick={() => {
                                
                                setFormData({ ...data, affiche: null })

                            }}>

                                
                                Affiche : <img src={"https://fairartsinschool.org/uploads/" + data.affiche} alt="" />
                                
                                Info importante : <p>{data.important}</p>
                                Date evenement à venir:<p>{data.dateEvent}</p>

                                Theme du festival : <p>{data.theme}</p>
                                texte de lancement festival : <p>{data.contenu}</p>
                                Date du festival : <p>{data.date}</p>

                            </div>

                        )
                    })}
                    <div className="edit">
                        <div className="container">
                            <h2>Modifier une info</h2>
                            <form onSubmit={handleSubmit} >
                                <div className="mb-3">
                                    <label htmlFor="nom" className="form-label">
                                        Evenement Important à venir :
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="important"
                                        name="important"
                                        value={formData.important}
                                        onChange={handleChange}

                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="dateEvent" className="form-label">
                                        Date de l'évènement à venir:
                                    </label>
                                    <input
                                        type='datetime-local'
                                        className="form-control"
                                        id="dateEvent"
                                        name="dateEvent"
                                        value={formData.dateEvent}
                                        onChange={handleChange}

                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Theme" className="form-label">
                                        Theme :
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="theme"
                                        name="theme"
                                        value={formData.theme}
                                        onChange={handleChange}

                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="contenu" className="form-label">
                                        Texte de lancement du festival :
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="contenu"
                                        name="contenu"
                                        value={formData.contenu}
                                        onChange={handleChange}
                                        placeholder="mettez plus d'information sur l'évènement à venir"

                                    ></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="date" className="form-label">
                                        Date du festival:
                                    </label>
                                    <input
                                        type='datetime-local'
                                        className="form-control"
                                        id="date"
                                        name="date"
                                        value={formData.date}
                                        onChange={handleChange}

                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="affiche" className="form-label">
                                        Affiche du festival :
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="affiche"
                                        name="affiche"
                                        onChange={handleChange}
                                        accept="image/*"

                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    Enregistrer
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="container text-center">
                       
                    </div>
                </div>
            </div>
    )
}