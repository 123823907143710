import React, { useState } from 'react';
import { Presenteur } from './Presenteur';


export const Selection = function({props_talents}){
    const [img,setImg] = useState(null)
    
    return(
       props_talents && <Presenteur titre={'Selection Officielle du Festival'} explication={'Retrouvez ici la sélection officielle du FairArts in school.'} img={props_talents} lienBouton={'/talents'}textBouton={'Toute la sélection'}/>
    )
}