import React from "react";
import './Bouton.css';
const Bouton = function ({children}){
    
        return(
            <>
                <button className="button">{children}</button>
            </>
        )
    
}

export default Bouton;
