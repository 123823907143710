import React from 'react';
import './Information.css';

const Information = ({item,rang}) => {
    return (
        <div className="information-unit" key={item.title}>
            <h2><span className='point'>{rang+1+'. '}</span>{item.title}</h2>
            <hr />
            <img src={"https://fairartsinschool.org/uploads/"+item.image} alt="fairarts"></img>
            <p>{item.contenu}</p>
        </div>
    )
}
export default Information;