import {React,useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import ArticlePreview from './ArticlePreview';
import './ArticlesPreviewList.css';


const ArticlePreviewList = () => {
    const [articles,setArticles] = useState([])
    const [windowSize,setWindowSize] = useState(window.innerWidth);
    useEffect(()=> {
        const handleReSize = (e) => {
            setWindowSize(window.innerWidth)
            
            console.log('taille écran ---> ',windowSize)
        };
        window.addEventListener('resize' , handleReSize)
       

        return () => {
            window.removeEventListener('resize',handleReSize)
           
        }
    })

    useEffect(()=>{
        fetch('https://fairarts.vercel.app/articles')
        .then(res=>res.json())
        .then((res)=>{
            setArticles(res)
        })
        .catch(error => console.log('erreur lors de la récupération des activités',error))
    },[])

    

    return (
        <div id="articleZone">
            <h3 id="lastActTitle">Dernières Actualités</h3>
            <p id="dernierActualites">Les dernières actualités du festival.</p>
            <div id="articleContainer">
                {
                    
                articles.slice(0,parseInt(windowSize/300)).map((item) => (
                    <ArticlePreview
                        key={item.id}
                        className="article"
                        id={item.id}
                        title={item.title}
                        content={item.content}
                    />
                ))

                
                }


            </div>
            <p id="voirTout">
                <Link to="/actualités">voir tout-{">"}</Link>
            </p>
        </div>
    );
};

export default ArticlePreviewList;
