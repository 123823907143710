import { HomePage } from './screens/HomePage';
import  ActualitesPage  from './screens/ActualitésPage';
import Formulaire from './screens/Inscription';
import AboutUsPage from './screens/AboutUsPage';
import ActivitesPage from './screens/ActivitésPage';
import TalentsPage from './screens/TalentsPage';
import Header from './component/Header';
import Footer from './component/Footer';
import {MobilFooter} from './component/MobilFooter';
import {F_Activites} from './admin/F_Activites'
import {F_Actualites} from './admin/F_Actualites'
import {F_Articles} from './admin/F_Articles'
import {F_Categories} from './admin/F_Categories'
import {F_Membres} from './admin/F_Membres'
import {F_NonRel} from './admin/F_NonRel'
import {F_Sliders} from './admin/F_Sliders'
import {F_Talents} from './admin/F_Talents'
import {F_Troupes} from './admin/F_Troupes'


import './App.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {AdminPanel} from './admin/Admin';
function App() {
  return (
    <Router>
      <div className='App'>
        <Header />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path='/inscription' element={<Formulaire />} />
          <Route path='/actualités' element={<ActualitesPage />} />
          <Route path='/AproposDeNous' element={<AboutUsPage />} />
          <Route path='/activites' element={<ActivitesPage />} />
          <Route path='/talents' element={<TalentsPage />} />
          <Route path='/admin' element={<AdminPanel />} />
          <Route path='/admin/F_Activites' element={<F_Activites />} />
          <Route path='/admin/F_Actualites' element={<F_Actualites />} />
          <Route path='/admin/F_Articles' element={<F_Articles />} />
          <Route path='/admin/F_Categories' element={<F_Categories />} />
          <Route path='/admin/F_Membres' element={<F_Membres />} />
          <Route path='/admin/F_Données du sites' element={<F_NonRel />} />
          <Route path='/admin/F_Sliders' element={<F_Sliders />} />
          <Route path='/admin/F_Talents' element={<F_Talents />} />
          <Route path='/admin/F_Troupes' element={<F_Troupes />} />

        </Routes>
        <MobilFooter id='mobilFooter' />
        <Footer id='footer' />
      </div>
    </Router>
  );
}

export default App;
