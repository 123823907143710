import React, { useState, useEffect } from "react";
import TitrePage from "../component/TitrePage";
import Information from "../component/Information";
import Loader from "./Loader";

const ActualitesPage = () => {
  const [actualites, setActualites] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://fairarts.vercel.app/actualites')
      .then(response => response.json())
      .then(data => {
        setActualites(data);
        setLoading(false); // Mettez à jour loading une fois les données chargées
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des actualites', error);
        setLoading(false); // Mettez à jour loading en cas d'erreur
      });
  }, []);

  return (
    <div id="ActualitesPage">
      {loading ? <Loader /> : (
        <>
          <TitrePage titre="Actualités du Festival" explication="Retrouvez ici toute l'actualité du festival Fair'Arts in school." />
          {actualites.map((item,index) => (
            <Information key={item.id} item={item} rang={index} />
          ))}
        </>
      )}
    </div>
  );
};

export default ActualitesPage;
