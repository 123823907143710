import { React, useEffect, useState } from 'react';
import { Lien } from './Lien'
import './admin.css'
import Loader from "../screens/Loader";




export const F_Articles = function () {
    const [articles, setArticles] = useState(null)
    const [loading, setLoading] = useState(true)
    const [edit, setEdit] = useState(-1)
    const [add, setAdd] = useState(false)

    const [formData, setFormData] = useState({
        title: '',
        contenu: '',
        
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name] : value
            
        }));
    };
    const deleteOne = (id) => {
        setLoading(true)
        fetch('https://fairarts.vercel.app/articles/' + id + '/destroy', {
            method: 'DELETE',
        })
            .then((response) => response.json())
            .then((res) => {
                console.log(res);
                setLoading(false)
            })
            .catch((e) => {
                console.log('erreur de suppression', e)
                setLoading(false)
            })
    }
    const handleAjouter = (e) => {
        e.preventDefault()
        
        const dataToSend = new FormData()
        dataToSend.append('title', formData.title)
        dataToSend.append('contenu', formData.contenu)

        
      
            console.log('je log le pb',{ ...formData });
            fetch('https://fairarts.vercel.app/articles/store',
            {
                method:'POST', headers: {
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify({ ...formData })
            })
            .then((res)=> res.json())
            .then((res)=>console.log(res))
            .catch(e => console.log(e))
            .finally(()=>setAdd(false))
   
        
        .catch(e=> console.log(e,'erreur d ajout article'))
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        const dataToSend = new FormData()
        dataToSend.append('title', formData.title)
        dataToSend.append('contenu', formData.contenu)

      

            fetch("https://fairarts.vercel.app/articles/" + edit + "/update", {
                method: 'PUT', headers: {
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify({ title: formData.title, contenu: formData.contenu }),
            })
                .then((res) => res.json())
                .then((res) => console.log(res))
                .catch((err) => console.log(err))

        setEdit(-1)


    };


    useEffect(() => {

        fetch('https://fairarts.vercel.app/articles').then(response => response.json())
            .then(data => {
                setArticles(data)
                setLoading(false)
            })
            .catch(e => console.log('Erreur lors de la récupération des activités', e))
        add && setEdit(false)
        edit && setAdd(false)

    })





    return (
        loading ? <Loader /> :
            <div className="adminZone">
                <nav >
                    <Lien vers={'F_activites'}></Lien>
                    <Lien vers={'F_articles'}></Lien>
                    <Lien vers={'F_actualites'}></Lien>
                    <Lien vers={'F_talents'}></Lien>
                    <Lien vers={'F_troupes'}></Lien>
                    <Lien vers={'F_Données du sites'}></Lien>
                    <Lien vers={'F_Categories'}></Lien>
                    <Lien vers={'F_Membres'}></Lien>
                    <Lien vers={'F_Sliders'}></Lien>

                </nav>

                <div className="affichage">

                    {articles.map((article, index) => {
                        return (
                            <div key={index} className='afficheur' onClick={() => {
                                setEdit(article.id)
                                setFormData({ ...article, image: null })

                            }}>

                                <h1>{article.title}</h1>
                                <hr></hr>
                               <p>{article.contenu}</p>
                                <button className="btn btn-danger btn-sm" onClick={() => deleteOne(article.id)}>Supprimer</button>

                            </div>

                        )
                    })}
                    {edit > 0 && <div className="edit">
                        <div className="container">
                            <h2>Modifier un article</h2>
                            <form onSubmit={handleSubmit} >
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">
                                        Titre :
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        name="title"
                                        value={formData.title}
                                        onChange={handleChange}

                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="contenu" className="form-label">
                                        Contenu :
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="contenu"
                                        name="contenu"
                                        value={formData.contenu}
                                        onChange={handleChange}

                                    ></textarea>
                                </div>
                                
                                <button type="submit" className="btn btn-primary">
                                    Enregistrer
                                </button>
                            </form>
                        </div>
                    </div>}
                    <div className="container text-center">
                        { !add ? <button class="btn btn-success float-right mr-2" onClick={() => {setAdd(true); setFormData({contenu:'',title:''})}}>Ajouter</button> :
                        <form className='edit' onSubmit={handleAjouter} >
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">
                                    Titre :
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}

                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="contenu" className="form-label">
                                    Contenu :
                                </label>
                                <textarea
                                    className="form-control"
                                    id="contenu"
                                    name="contenu"
                                    value={formData.contenu}
                                    onChange={handleChange}

                                ></textarea>
                            </div>
                            
                            <button type="submit" className="btn btn-primary">
                                Ajouter
                            </button>
                        </form>}
                    </div>
                </div>
            </div>
    )
}