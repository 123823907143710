import React, { useEffect, useState } from 'react';
import './HomePage.css';
import { Slider } from '../component/Slider';
import { Inscription } from '../component/Inscription';
import { Selection } from '../component/Selection';
import img from '../images/5 (2).jpg';
import { Link } from 'react-router-dom';
import ArticlePreviewList from '../component/ArticlesPreviewList';
import PubDeroulement from '../component/PubDeroulement';
import { Timer } from '../component/Timer';
import Loader from './Loader';


export const HomePage = function ({ className }) {


    function calculateTimeRemaining() {
        const now = new Date();

        const difference = new Date(dateEvenement).getTime() - now.getTime();
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    
        return { days, hours, minutes, seconds };
      }

      function formatTime(unit) {
        return unit < 10 ? `0${unit}` : unit;
      }
    

    const [important, setImportant] = useState('');
    const [infoPostuler, setInfoPostuler] = useState(null);
    const [sliders, setSliders] = useState(null);
    const [images, setImages] = useState(null);
    const [activites, setActivites] = useState(null);
    const [talents, setTalents] = useState(null);
    const [dateEvenement, setDateEvenement] = useState(null);
    const [loading,setLoading] = useState(true)
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
    useEffect(() => {
        // Fetch sliders
        fetch('https://fairarts.vercel.app/sliders')
            .then(response => response.json())
            .then(data => {
                setSliders(data)
                let tab = []
                data.forEach(element => {
                    tab.push("https://fairartsinschool.org/uploads/"+element.image)


                });
                setImages(tab)
            })
            .catch(error => console.log('Error fetching sliders:', error));

        // Fetch non-relational data
        fetch('https://fairarts.vercel.app/nonRelData')
            .then(response => response.json())
            .then(data => {
                !data[0] && setInfoPostuler({})
                data[0] &&  setImportant(data[0].important);
                data[0] &&  setInfoPostuler(data[0]);
                data[0] &&  setDateEvenement(data[0].dateEvent)
            })
            .catch(error => console.log('Error fetching non-relational data:', error));


            //fetch des activites
            fetch('https://fairarts.vercel.app/activites')
            .then(response =>response.json())
            .then((data)=>{
              let tab = []
              data.forEach(element => {
                tab.push("https://fairartsinschool.org/uploads/"+element.image)
              });
              setActivites(tab)
            })
            .catch(error => console.log('Error lors de la récupération des activites:', error));

            //sélection

        fetch('https://fairarts.vercel.app/talents')
        .then(res=>res.json())
        .then((res)=>{
            let tab = []
            res.forEach(element => {
                tab.push("https://fairartsinschool.org/uploads/"+element.image)
            })
            
            setTalents(tab)
        })
        .catch(error => console.log('Error fetching talents:', error));
        
        if(talents && activites && infoPostuler && sliders){
            setLoading(false)
        }else{
            setLoading(true)
        }

    }, []);


     useEffect(() => {
        if(talents && activites && infoPostuler && sliders){
            setLoading(false)
        }else{
            setLoading(true)
        }
        
      }, [talents , activites , infoPostuler , sliders]); 

    return (

        loading ? <Loader/>:

        <div className='color-site'>
            <header>
                <div className='container  d-flex align-items-center justify-content-center '>
                    <div className='container bg-dark'>
                        <h2 className='text-center my-3 text-blanc'>{important} 
                        {dateEvenement && <Timer dateEvenementProps={dateEvenement}/>}
                        </h2>
                    </div>
                </div>
            </header>
            {sliders && <Slider props_slider={sliders} props_images={images} className='composant' />}
            <ArticlePreviewList className='composant' />

            {infoPostuler && <Inscription className='composant' theme={infoPostuler.theme} affiche={infoPostuler.affiche} date={infoPostuler.date} contenu={infoPostuler.contenu} />}
{            activites && <PubDeroulement className='composant' props_activites={activites} />
}           
{talents && <Selection props_talents={talents}  />
}        </div>
    );
}
