import { React, useEffect, useState } from 'react';
import { Lien } from './Lien'
import './admin.css'
import Loader from "../screens/Loader";




export const F_Categories = function () {
    const [categories, setCategories] = useState(null)
    const [loading, setLoading] = useState(true)
    const [edit, setEdit] = useState(-1)
    const [add, setAdd] = useState(false)

    const [formData, setFormData] = useState({
        intitule: '',
        
        
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name] : value
            
        }));
    };
    const deleteOne = (id) => {
        setLoading(true)
        fetch('https://fairarts.vercel.app/categories/' + id + '/destroy', {
            method: 'DELETE',
        })
            .then((response) => response.json())
            .then((res) => {
                console.log(res);
                setLoading(false)
            })
            .catch((e) => {
                console.log('erreur de suppression', e)
                setLoading(false)
            })
    }
    const handleAjouter = (e) => {
        e.preventDefault()
        
        const dataToSend = new FormData()
        dataToSend.append('intitule', formData.intitule)
      
      
            fetch('https://fairarts.vercel.app/categories/store',
            {
                method:'POST', headers: {
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify({ ...formData })
            })
            .then((res)=> res.json())
            .then((res)=>console.log(res))
            .catch(e => console.log(e))
            .finally(()=>setAdd(false))
   
        
        .catch(e=> console.log(e,'erreur d ajout article'))
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        const dataToSend = new FormData()
        dataToSend.append('intitule', formData.intitule)
    
            fetch("https://fairarts.vercel.app/categories/" + edit + "/update", {
                method: 'PUT', headers: {
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify({ intitule: formData.intitule}),
            })
                .then((res) => res.json())
                .then((res) => console.log(res))
                .catch((err) => console.log(err))

        setEdit(-1)


    };


    useEffect(() => {

        fetch('https://fairarts.vercel.app/categories').then(response => response.json())
            .then(data => {
                setCategories(data)
                setLoading(false)
            })
            .catch(e => console.log('Erreur lors de la récupération des catégories', e))
        add && setEdit(false)
        edit && setAdd(false)

    })





    return (
        loading ? <Loader /> :
            <div className="adminZone">
                <nav >
                    <Lien vers={'F_activites'}></Lien>
                    <Lien vers={'F_articles'}></Lien>
                    <Lien vers={'F_actualites'}></Lien>
                    <Lien vers={'F_talents'}></Lien>
                    <Lien vers={'F_troupes'}></Lien>
                    <Lien vers={'F_Données du sites'}></Lien>
                    <Lien vers={'F_Categories'}></Lien>
                    <Lien vers={'F_Membres'}></Lien>
                    <Lien vers={'F_Sliders'}></Lien>

                </nav>

                <div className="affichage">

                    {categories.map((categorie, index) => {
                        return (
                            <div key={index} className='afficheur' onClick={() => {
                                setEdit(categorie.id)
                                setFormData({ ...categorie })

                            }}>

                                <h1>{categorie.intitule}</h1>
                                
                               
                                <button className="btn btn-danger btn-sm" onClick={() => deleteOne(categorie.id)}>Supprimer</button>

                            </div>

                        )
                    })}
                    {edit > 0 && <div className="edit">
                        <div className="container">
                            <h2>Modifier un catégorie</h2>
                            <form onSubmit={handleSubmit} >
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">
                                        intitulé :
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="intitule"
                                        name="intitule"
                                        value={formData.intitule}
                                        onChange={handleChange}

                                    />
                                </div>
                                
                                
                                <button type="submit" className="btn btn-primary">
                                    Enregistrer
                                </button>
                            </form>
                        </div>
                    </div>}
                    <div className="container text-center">
                        { !add ? <button class="btn btn-success float-right mr-2" onClick={() => {setAdd(true); setFormData({intitule: ''})}}>Ajouter</button> :
                        <form className='edit' onSubmit={handleAjouter} >
                            <div className="mb-3">
                                <label htmlFor="intitule" className="form-label">
                                    Intitulé :
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="intitule"
                                    name="intitule"
                                    value={formData.intitule}
                                    onChange={handleChange}

                                />
                            </div>
                            
                            
                            <button type="submit" className="btn btn-primary">
                                Ajouter
                            </button>
                        </form>}
                    </div>
                </div>
            </div>
    )
}