import React from "react";
import './mobilFooter.css';
import Footer from './Footer';
import logo from '../logo.svg';


export const MobilFooter = function ({ id }) {
    const editionEnCours = "7e edtion Fair'Arts in school du 10 Mars au 26 Mai 2024.";

    return (
        <div id={id}>
            <h1>{editionEnCours}</h1><br />
            <div>
                <Footer id='footerInMobil' />
               
                
            </div>
        </div>

    )

}

