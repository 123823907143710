import React, { useEffect, useState } from "react";
import "./AboutUsPage.css";
import Loader from "./Loader";
import Bouton from "../component/Bouton";
import { Link } from 'react-router-dom';


const AboutUsPage = () => {
   
    const [membre,setMembre] = useState([])
    const [loading,setLoading] = useState(true)


    useEffect(() => {
      if(membre.length>0){
          setLoading(false)
      }else{
          setLoading(true)
      }
      
    }, [membre]); 

    useEffect(()=>{
        fetch('https://fairarts.vercel.app/membres')
        .then(res=>res.json())
        .then((res)=>{
            setMembre(res)
            setLoading(false)
        })
        .catch(e => console.log('Erreur lors de la récupération des Membres',e))

    },[])
  return (
    <>
      {loading ? <Loader/> :
      <div id="AboutUsPage">
        <h1>
          Qui Sommes-Nous <span className="point">?</span>
        </h1>
        <hr className="trame 95" />
        {membre.map((item) => (
          <div key={membre.indexOf(item)} className="membre">
            <img src={"https://fairarts.org/uploads/"+item.photo} alt="" />
            <h2 className="nom">{item.nom}</h2>
            <hr className="trame" />
            <p>{item.poste}</p>
            <p>{item.description}</p>
          </div>
        ))}
      </div>}
      <Link to='/admin'><button>Admin</button></Link>
    </>
  );
};

export default AboutUsPage;
