import { React, useEffect, useState } from 'react';
import Information from '../component/Information';
import TitrePage from '../component/TitrePage';
import './TalentsPage.css';
import Loader from './Loader';

const TalentsPage = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://fairarts.vercel.app/talents')
      .then(response => response.json())
      .then(data => {
        
        const categorizedTalents = {};
        data.forEach(talent => {
          const categoryId = talent.categorie_id;

          if (categoryId in categorizedTalents) {
            categorizedTalents[categoryId].push(talent);
          } else {
            categorizedTalents[categoryId] = [talent];
          }
        });

        const categoriesArray = Object.entries(categorizedTalents).map(([intitule, talents]) => ({
          intitule,
          talents,
        }));

        setCategories(categoriesArray);
        setLoading(false)
      })
      .catch(error => console.error('Erreur lors de la récupération des talents', error));
  }, []);


  useEffect(() => {
    if(categories.length>0){
      setLoading(false)
    }
    else{
      setLoading(true)
    }
  
  }, [categories]);

  return (
    loading ? <Loader/> : 
    <>
      <TitrePage
        titre='Meilleurs Talents du Festival'
        explication="Retrouvez ici tous les meilleurs talents classés par catégories du festival Fair'Arts in school."
      />
      {categories.map(categorie => (
        <div key={categorie.intitule}>
          <h1 className='cadre bordeau m80'>
            MEILLEURS TALENTS DE LA CATEGORIE {categorie.intitule.toUpperCase()}
          </h1>
          {categorie.talents.map((talent,index) => (
            <Information key={talent.id} item={talent} rang={index} />
          ))}
        </div>
      ))}
    </>
  );
};

export default TalentsPage;
