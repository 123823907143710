import { React, useEffect, useState } from 'react';
import { Lien } from './Lien'
import './admin.css'
import Loader from "../screens/Loader";
import TitrePage from "../component/TitrePage";




export const F_Activites = function () {
    const [activites, setActivites] = useState(null)
    const [loading, setLoading] = useState(true)
    const [edit, setEdit] = useState(-1)
    const [add, setAdd] = useState(false)

    const [formData, setFormData] = useState({
        title: '',
        contenu: '',
        image: null,
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === 'image' ? files[0] : value,
        }));
    };
    const deleteOne = (id) => {
        setLoading(true)
        fetch('https://fairarts.vercel.app/activites/' + id + '/destroy', {
            method: 'DELETE',
        })
            .then((response) => response.json())
            .then((res) => {
                console.log(res);
                setLoading(false)
            })
            .catch((e) => {
                console.log('erreur de suppression', e)
                setLoading(false)
            })
    }
    const handleAjouter = (e) => {
        e.preventDefault()
        
        const dataToSend = new FormData()
        dataToSend.append('image', formData.image)
        dataToSend.append('title', formData.title)
        dataToSend.append('contenu', formData.contenu)

        fetch("https://fairarts.vercel.app/image", {
            method: 'POST',


            body: dataToSend,
        }
        )
        .then((response) => response.json())
        .then((data)=>{
            console.log('je log le pb',{ ...formData, image: formData.image.name });
            fetch('https://fairarts.vercel.app/activites/store',
            {
                method:'POST', headers: {
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify({ ...formData, image: formData.image.name })
            })
            .then((res)=> res.json())
            .then((res)=>console.log(res))
            .catch(e => console.log(e))
            .finally(()=>setAdd(false))
        })
        
        .catch(e=> console.log(e,'erreur d ajout activité'))
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        const dataToSend = new FormData()
        dataToSend.append('image', formData.image)
        dataToSend.append('title', formData.title)
        dataToSend.append('contenu', formData.contenu)

        formData.image ? fetch("http://localhost:3001/image", {
            method: 'POST', headers: {
                    'Content-Type': 'application/json',
                },


            body: JSON.stringify(dataToSend),
        }
        )
            .then((response) => response.json())
            .then(donnee => {

                console.log('on y est ok ok ');
                fetch("https://fairarts.vercel.app/activites/" + edit + "/update", {
                    method: 'PUT', headers: {
                        'Content-Type': 'application/json',
                    },

                    body: JSON.stringify({ ...formData, image: formData.image.name }),
                })
                    .then((res) => res.json())
                    .then((res) => console.log(res))
                    .catch((err) => console.log(err))

            })
            .catch((err) => console.log(err)) :

            fetch("https://fairarts.vercel.app/activites/" + edit + "/update", {
                method: 'PUT', headers: {
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify({ title: formData.title, contenu: formData.contenu }),
            })
                .then((res) => res.json())
                .then((res) => console.log(res))
                .catch((err) => console.log(err))

        setEdit(-1)


    };


    useEffect(() => {

        fetch('https://fairarts.vercel.app/activites').then(response => response.json())
            .then(data => {
                setActivites(data)
                setLoading(false)
            })
            .catch(e => console.log('Erreur lors de la récupération des activités', e))
        add && setEdit(false)
        edit && setAdd(false)

    })





    return (
        loading ? <Loader /> :
            <div className="adminZone">
                <nav >
                    <Lien vers={'F_activites'}></Lien>
                    <Lien vers={'F_articles'}></Lien>
                    <Lien vers={'F_actualites'}></Lien>
                    <Lien vers={'F_talents'}></Lien>
                    <Lien vers={'F_troupes'}></Lien>
                    <Lien vers={'F_Données du sites'}></Lien>
                    <Lien vers={'F_Categories'}></Lien>
                    <Lien vers={'F_Membres'}></Lien>
                    <Lien vers={'F_Sliders'}></Lien>

                </nav>

                <div className="affichage">

                    {activites.map((activite, index) => {
                        return (
                            <div key={index} className='afficheur' onClick={() => {
                                setEdit(activite.id)
                                setFormData({ ...activite, image: null })

                            }}>

                                <h1>{activite.title}</h1>
                                <img src={"https://fairartsinschool.org/uploads/" + activite.image} alt="" />
                                <p>{activite.contenu}</p>
                                <button className="btn btn-danger btn-sm" onClick={() => deleteOne(activite.id)}>Supprimer</button>

                            </div>

                        )
                    })}
                    {edit > 0 && <div className="edit">
                        <div className="container">
                            <h2>Modifier une activité</h2>
                            <form onSubmit={handleSubmit} >
                                <div className="mb-3">
                                    <label htmlFor="title" className="form-label">
                                        Titre :
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="title"
                                        name="title"
                                        value={formData.title}
                                        onChange={handleChange}

                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="contenu" className="form-label">
                                        Contenu :
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="contenu"
                                        name="contenu"
                                        value={formData.contenu}
                                        onChange={handleChange}

                                    ></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="image" className="form-label">
                                        Image :
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="image"
                                        name="image"
                                        onChange={handleChange}
                                        accept="image/*"

                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    Enregistrer
                                </button>
                            </form>
                        </div>
                    </div>}
                    <div className="container text-center">
                        { !add ? <button class="btn btn-success float-right mr-2" onClick={() => {setAdd(true); setFormData({image:null,contenu:'',title:''})}}>Ajouter</button> :
                        <form className='edit' onSubmit={handleAjouter} >
                            <div className="mb-3">
                                <label htmlFor="title" className="form-label">
                                    Titre :
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}

                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="contenu" className="form-label">
                                    Contenu :
                                </label>
                                <textarea
                                    className="form-control"
                                    id="contenu"
                                    name="contenu"
                                    value={formData.contenu}
                                    onChange={handleChange}

                                ></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="image" className="form-label">
                                    Image :
                                </label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="image"
                                    name="image"
                                    onChange={handleChange}
                                    accept="image/*"

                                />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                Ajouter
                            </button>
                        </form>}
                    </div>
                </div>
            </div>
    )
}