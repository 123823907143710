import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import './PubDeroulement.css';


const PubDeroulement = ({ className,props_activites }) => {


  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
   setImages(props_activites)
  }, []);



  useEffect(() => {
    // Défilement automatique des images toutes les 3 secondes
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    // Nettoyage de l'intervalle lorsque le composant est démonté
    return () => {
      clearInterval(interval);
    };
  }, [images]);


  return (
    <div id="pubDeroulement">
      <h1>
        Les Activités du festival

      </h1>
      <hr className="trame" />
      <p>
        Consultez ici les differentes activités dans les differents ateliers du Fair'Arts in school 7e edition.
      </p>
      <img src={images[currentImageIndex]} alt="">
      </img>
      <Link to="/activites">
        Voir tout -{">"}

      </Link>




    </div>
  )
}



export default PubDeroulement;