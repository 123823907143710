import React from 'react';
import './lien.css'
import { Link } from 'react-router-dom';

export function Lien({vers}) {
    return (
        <Link to={'/admin/'+vers}>
            <div className='lienAdmin'>
                {vers}

            </div>
        </Link>
    )
}