import { React, useEffect, useState } from 'react';
import { Lien } from './Lien'
import './admin.css'
import Loader from "../screens/Loader";




export const F_Membres = function () {
    const [membres, setMembres] = useState(null)
    const [loading, setLoading] = useState(true)
    const [edit, setEdit] = useState(-1)
    const [add, setAdd] = useState(false)

    const [formData, setFormData] = useState({
        nom: '',
        poste: '',
        description:'',
        photo: null,
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === 'photo' ? files[0] : value,
        }));
    };
    const deleteOne = (id) => {
        setLoading(true)
        fetch('https://fairarts.vercel.app/membres/' + id + '/destroy', {
            method: 'DELETE',
        })
            .then((response) => response.json())
            .then((res) => {
                console.log(res);
                setLoading(false)
            })
            .catch((e) => {
                console.log('erreur de suppression', e)
                setLoading(false)
            })
    }
    const handleAjouter = (e) => {
        e.preventDefault()
        
        const dataToSend = new FormData()
        dataToSend.append('nom', formData.nom)
        dataToSend.append('poste', formData.poste)
        dataToSend.append('description', formData.description)
        dataToSend.append('image', formData.photo)

        fetch("https://fairarts.vercel.app/image", {
            method:'POST',
            body: dataToSend,
        }
        )
        .then((response) => response.json())
        .then((data)=>{
            fetch('https://fairarts.vercel.app/membres/store',
            {
                method:'POST', headers: {
                    'Content-Type': 'application/json',
                },
                body:JSON.stringify({ ...formData, photo: formData.photo.name })
            })
            .then((res)=> res.json())
            .then((res)=>console.log(res))
            .catch(e => console.log(e))
            .finally(()=>setAdd(false))
        })
        
        .catch(e=> console.log(e,'erreur d ajout de membre'))
    }
    const handleSubmit = (e) => {
        e.preventDefault();

        const dataToSend = new FormData()
        dataToSend.append('image', formData.photo)
        dataToSend.append('poste', formData.poste)
        dataToSend.append('description', formData.description)
        dataToSend.append('nom', formData.nom)

        formData.photo ? fetch("https://fairarts.vercel.app/image", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },


            body: JSON.stringify(dataToSend),
        }
        )
            .then((response) => response.json())
            .then(donnee => {

                console.log('on y est ok ok ',donnee);
                fetch("https://fairarts.vercel.app/membres/" + edit + "/update", {
                    method: 'PUT', headers: {
                        'Content-Type': 'application/json',
                    },

                    body: JSON.stringify({ ...formData, photo: formData.photo.name }),
                })
                    .then((res) => res.json())
                    .then((res) => console.log(res))
                    .catch((err) => console.log(err))

            })
            .catch((err) => console.log(err)) :

            fetch("https://fairarts.vercel.app/membres/" + edit + "/update", {
                method: 'PUT', headers: {
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify({ nom: formData.nom, poste: formData.poste, description: formData.description }),
            })
                .then((res) => res.json())
                .then((res) => console.log(res))
                .catch((err) => console.log(err))

        setEdit(-1)


    };


    useEffect(() => {

        fetch('https://fairarts.vercel.app/membres').then(response => response.json())
            .then(data => {
                setMembres(data)
                setLoading(false)
            })
            .catch(e => console.log('Erreur lors de la récupération des membres', e))
        add && setEdit(false)
        edit && setAdd(false)

    })





    return (
        loading ? <Loader /> :
            <div className="adminZone">
                <nav >
                    <Lien vers={'F_activites'}></Lien>
                    <Lien vers={'F_articles'}></Lien>
                    <Lien vers={'F_actualites'}></Lien>
                    <Lien vers={'F_talents'}></Lien>
                    <Lien vers={'F_troupes'}></Lien>
                    <Lien vers={'F_Données du sites'}></Lien>
                    <Lien vers={'F_Categories'}></Lien>
                    <Lien vers={'F_Membres'}></Lien>
                    <Lien vers={'F_Sliders'}></Lien>

                </nav>

                <div className="affichage">

                    {membres.map((membre, index) => {
                        return (
                            <div key={index} className='afficheur' onClick={() => {
                                setEdit(membre.id)
                                setFormData({ ...membre, photo: null })

                            }}>

                                
                                <img src={"https://fairartsinschool.org/uploads/" + membre.photo} alt="" />
                                <hr></hr>
                                <h1>{membre.nom}</h1>
                                <h2>{membre.poste}</h2>
                                <p>{membre.description}</p>
                                <button className="btn btn-danger btn-sm" onClick={() => deleteOne(membre.id)}>Supprimer</button>

                            </div>

                        )
                    })}
                    {edit > 0 && <div className="edit">
                        <div className="container">
                            <h2>Modifier un membre</h2>
                            <form onSubmit={handleSubmit} >
                                <div className="mb-3">
                                    <label htmlFor="nom" className="form-label">
                                        Nom :
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="nom"
                                        name="nom"
                                        value={formData.nom}
                                        onChange={handleChange}

                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="poste" className="form-label">
                                        Poste :
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="poste"
                                        name="poste"
                                        value={formData.poste}
                                        onChange={handleChange}

                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">
                                        Description :
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="description"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}

                                    ></textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="photo" className="form-label">
                                        Photo :
                                    </label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="photo"
                                        name="photo"
                                        onChange={handleChange}
                                        accept="image/*"

                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    Enregistrer
                                </button>
                            </form>
                        </div>
                    </div>}
                    <div className="container text-center">
                        { !add ? <button class="btn btn-success float-right mr-2" onClick={() => {setAdd(true); setFormData({photo:null,nom:'',poste:'',description:''})}}>Ajouter</button> :
                        <form onSubmit={handleAjouter} >
                        <div className="mb-3">
                            <label htmlFor="nom" className="form-label">
                                Nom :
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="nom"
                                name="nom"
                                value={formData.nom}
                                onChange={handleChange}

                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="poste" className="form-label">
                                Poste :
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="poste"
                                name="poste"
                                value={formData.poste}
                                onChange={handleChange}

                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="description" className="form-label">
                                Description :
                            </label>
                            <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}

                            ></textarea>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="photo" className="form-label">
                                Photo :
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                id="photo"
                                name="photo"
                                onChange={handleChange}
                                accept="image/*"

                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Ajouter
                        </button>
                    </form>}
                    </div>
                </div>
            </div>
    )
}