import React, { Component } from 'react';
import { Link } from "react-router-dom";

class ArticlePreview extends Component {




    render() {
        return (
            <div className='articleBoite'>
                <Link to="/actualités">
                <div className={this.props.className} id={this.props.id} >
                    <h3 className='titleArticle'>{this.props.title}</h3>
                    <hr />
                    {this.props.content && this.props.content.length > 70 && <p className='contentArticle'>{`${this.props.content.substring(0, 70)}  ...`}</p>}
                    {this.props.content && this.props.content.length < 70 && <p className='contentArticle'>{this.props.content}</p>}

                </div></Link>
            </div>
        )
    }

}

export default ArticlePreview;