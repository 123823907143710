import React from 'react';
import './Slide.css';
import { Link } from 'react-router-dom'
import Bouton from './Bouton';

export const Slide = function ({img,date,className,title,contenu}) {
    return (
        <div className={'container px-0 slide '+className}>
            <div className='row'>
            <div className='col-md-6 '>
                <img className='img-slide ' src={img} alt=''/>
            </div>
            <div className='col-md-6 my-0'>
                <h2 className='text-center text-pourcent-130'>{title}</h2>
                <p className='text-center text-pourcent-100'>{`${new Date(date).getFullYear()}/${(new Date(date).getMonth() + 1).toString().padStart(2, '0')}/${new Date(date).getDate().toString().padStart(2, '0')}`+'\n'+contenu} </p>
                <div className='d-flex justify-content-center'><Bouton>Inscrivez-Vous</Bouton></div>
            </div>
            </div>
        </div>
        
    )
}