import React, { useEffect, useRef, useState } from 'react';
import { Slide } from './Slide';
import { Inscription } from './Inscription';
import './Slider.css';
import img0 from '../logo.svg';
import left from '../assets/left.svg';
import right from '../assets/right.svg';
import img1 from '../images/11 (2).jpg'
import img2 from '../images/1 (2).jpg'
import img3 from '../images/3 (2).jpg'
import img4 from '../images/5 (2).jpg'
import img5 from '../images/6 (2).jpg'
import img6 from '../images/8 (2).jpg'

const useImages = function (images) {
    const imagesRef = []
    const ref = useRef()
    for (let i = 0; i < images.length; i++) {
        const image = images[i];
        imagesRef.push({
            title: image.title,
            contenu: image.contenu,
            date:image.date,
            src: "https://fairartsinschool.org/uploads/"+image.image,
            ref: ref,
            visible: i === 0 ? true : false,
            direction: i === 0 ? '' : 'prev',
        })

    }
    const [images1, setImages] = useState(imagesRef)
    //return imagesRef
    return [images1, setImages]
}

export const Slider = function ({ className, props_slider,props_images }) {

    const [images, setImages2] = useImages(props_slider)
    const [slider, setSlider] = useState([])
    const [swiperDirection, setSwiperDirection] = useState(null);
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    let timer = null
    const handleChange = function () {

        if (timer) {
            clearInterval(timer)
            timer = null
        }
        timer = setInterval(() => {
            handleLeft()
        }, 6000);
    }

    useEffect(() => {
        handleChange()
        console.log(images);

        return () => {
            clearInterval(timer)
        }
    }, [images])
    useEffect(() => {
        const handleReSize = (e) => {
            setWindowSize(window.innerWidth)
            console.log('taille écran ---> ', windowSize)
        };
        window.addEventListener('resize', handleReSize)


        return () => {
            window.removeEventListener('resize', handleReSize)

        }
    })


    /* useEffect(() => {
        
                setSlider(props_slider)
                let tab = []
                props_slider.forEach(element => {
                    tab.push("https://fairarts.vercel.app/image/"+element.image)


                });
                setImages2(tab)
    }, []) */


    const handleLeft = function (e) {
        const index = images.findIndex(img => img.visible)
        const imagesCopies = images.map((img, i) => {
            return {
                ...img,
                direction: 'prev',
                visible: false
            }
        })

        imagesCopies[index].direction = 'prev-left'

        if (index === 0) {
            imagesCopies[images.length - 1].visible = true
            imagesCopies[images.length - 1].direction = 'next-left'
        }
        else {
            imagesCopies[index - 1].visible = true
            imagesCopies[index - 1].direction = 'next-left'
        }
        setImages2(imagesCopies)
    }
    const handleRight = function (e) {
        const index = images.findIndex(img => img.visible)
        const imagesCopies = images.map((img, i) => {
            return {
                ...img,
                direction: 'prev',
                visible: false
            }
        })

        imagesCopies[index].direction = 'prev-left'

        if (index >= images.length - 1) {
            imagesCopies[0].visible = true
            imagesCopies[0].direction = 'next-left'
        }
        else {
            imagesCopies[index + 1].visible = true
            imagesCopies[index + 1].direction = 'next-left'
        }
        setImages2(imagesCopies)
    }

    return (<>
    

        {windowSize > 630 ? (
            <div className={'container-fluid slider ' + className}>
                <div className='row'>
                    <div className='col-1 col-md-1 col-sm-1 d-flex justify-content-end align-items-center px-0'><img className='slide-lr d-sm-block' src={left} onClick={() => handleLeft()} alt='' /></div>
                    <div className='col-10 col-md-10 col-sm-10  d-flex slider-direct justify-content-center align-items-center overflow-hidden'>
                        {
                            images.map(
                                (img, index) => <Slide key={'img' + index} className={(img.visible ? ' slide-visible ' : ' slide-not-visible ') + img.direction}
                                    img={img.src}
                                    date={img.date}
                                    title={img.title}
                                    contenu={img.contenu}
                                />
                            )
                        }
                    </div>
                    <div className=' col-1 col-md-1 col-sm-1 d-flex justify-content-start align-items-center px-0'><img className='slide-lr' onClick={handleRight} src={right} alt='' /> </div>
                </div>
            </div>)
            : (
                <div className={'container-fluid slider ' + className}>
                    <div className='row px-0 mb-3'>

                        <div className='  px-0 d-flex slider-direct justify-content-center align-items-center overflow-hidden'>
                            {
                                images.map(
                                    (img, index) => <Slide key={'img' + index} className={(img.visible ? ' slide-visible ' : ' slide-not-visible ') + img.direction}
                                        img={img.src}
                                        date={img.date}
                                        titre={img.titre}
                                        contenu={img.contenu}
                                    />
                                )
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-6  d-flex justify-content-end px-0'>
                            <div className=' border border-black w-40 px-2 d-flex justify-content-end'>
                                <img className='slide-lr ' onClick={handleLeft} src={left} alt='' />
                            </div>
                        </div>
                        <div className='col-6 d-flex justify-content-start px-0'>
                            <div className='border border-dark w-40 px-2 d-flex justify-content-start'>
                                <img className='slide-lr' onClick={handleRight} src={right} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    </>
    )
}